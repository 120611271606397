<template>
  <div class="pl-3 pr-3 pb-10 mt-14 mb-8 max-w-8xl m-auto">
    <div class="flex justify-between">
      <div
        class="w-full max-w-lg lg:w-1/2 md:pt-10 lg:pt-32 lg:pl-10 m-auto lg:m-0"
      >
        <nuxt-img
          class="lg:hidden top-[-40px] left-[185px] relative"
          alt="Gear Image"
          src="/images/icons/home/gear.svg"
        />
        <h1
          class="text-4xl md:text-5xl font-semibold tracking-normal text-black-mid"
        >
          Easy way to hire
        </h1>
        <h3 class="text-lg text-blue-steel mt-3">
          You'll be able to hire just about anything with us!
        </h3>

        <BookingPostcode />

        <nuxt-img
          class="lg:hidden top-[-35px] right-[45px] relative z-0"
          width="60px"
          alt="Gear Image"
          src="/images/icons/home/gear.svg"
        />
        <nuxt-img
          class="lg:hidden top-[45px] left-[187px] relative"
          width="52px"
          alt="Gear Image"
          src="/images/icons/home/gear.svg"
        />
      </div>
      <div class="w-1/2 hidden lg:block relative">
        <nuxt-img
          class="w-full pr-12"
          sizes="sm:90px md:100px lg:1000px"
          alt="Hire Image"
          src="/images/pages/home/hero.png"
        />

        <div
          class="absolute left-[180px] top-[54px] xl:left-[220px] xl:top-[94px] border border-blue-light-one bg-white p-6 rounded-xl flex w-[310px] shadow-[0px_0px_10px_#e6e8ef] shadow-blue-light-four"
        >
          <div
            class="bg-[#f1f4fc] border border-[#e6e8ef] p-3 rounded-xl"
          >
            <nuxt-img
              sizes="sm:100vw md:100vw lg:100vw"
              alt="Drill Hire"
              src="/images/icons/home/driller.svg"
            />
          </div>
          <div class="pl-5 w-full">
            <div
              class="font-semibold text-xl flex items-center justify-between w-full"
            >
              <h4>Drill</h4>
              <span class="text-green font-normal text-sm">£30</span>
            </div>
            <div class="flex items-center space-x-1 mt-1">
              <nuxt-img
                sizes="sm:14px md:14px lg:14px"
                alt="Drill Hire"
                src="/images/icons/maps/location-pin.svg"
              />
              <p class="text-sm text-blue-steel">Sunderland</p>
            </div>
          </div>
        </div>

        <div
          class="absolute bottom-[100px] left-[62px] border border-blue-light-one bg-white p-6 rounded-xl flex w-[310px] shadow-[0px_0px_10px_#e6e8ef] shadow-blue-light-four"
        >
          <div
            class="bg-[#f1f4fc] border border-[#e6e8ef] p-3 rounded-xl"
          >
            <nuxt-img
              sizes="sm:100vw md:100vw lg:100vw"
              alt="Drill Hire"
              src="/images/icons/home/digger.svg"
            />
          </div>
          <div class="pl-5 w-full">
            <div
              class="font-semibold text-xl flex items-center justify-between w-full"
            >
              <h4>Breaker</h4>
              <span class="text-green font-normal text-sm">£25</span>
            </div>
            <div class="flex items-center space-x-1 mt-1">
              <nuxt-img
                sizes="sm:14px md:14px lg:14px"
                alt="Drill Hire"
                src="/images/icons/maps/location-pin.svg"
              />
              <p class="text-sm text-blue-steel">Bristol</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
